<template><Component :is="useAsyncIcon(name, type)" :style="computedStyle" /></template>

<script setup lang="ts">
    import { computed } from '@vue/runtime-core'
    import { useAsyncIcon } from '@/scripts/hooks/useAsyncIcon'

    const props = defineProps({
        name: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'regular',
        },
        fill: {
            type: String,
            default: 'currentColor',
        },
        opacity: {
            type: String,
            default: '1',
        },
        secondaryFill: {
            type: String,
            default: 'currentColor',
        },
        secondaryOpacity: {
            type: String,
            default: '0.4',
        },
    })

    const computedStyle = computed(() => ({
        '--fa-primary-color': props.fill,
        '--fa-primary-opacity': props.opacity,
        '--fa-secondary-color': props.secondaryFill,
        '--fa-secondary-opacity': props.secondaryOpacity,
    }))
</script>
