import { customRef } from '@vue/runtime-core'

export function useDebouncedRef(value: any, delay: number) {
    let timeout: NodeJS.Timeout

    return customRef((track, trigger) => {
        return {
            get() {
                track()
                return value
            },
            set(newValue) {
                clearTimeout(timeout)

                timeout = setTimeout(() => {
                    value = newValue
                    trigger()
                }, delay)
            }
        }
    })
}
