import spacetime, { ParsableDate } from 'spacetime'

const timezone = spacetime().tz

export function useSpacetime() {
    const parse = (date: string) => spacetime(date)
    const niceFullYear = (date: string) =>
        parse(date).format('{day} {month} {date-ordinal} {year}, {hour-24}:{minute-pad}')
    const niceDate = (date: string) => parse(date).format('{day} {month} {date-ordinal} {year}')
    const ymd = (date: string) => parse(date).format('y/m/d')
    const toLocalTime = (dateTime: ParsableDate) => spacetime(dateTime).goto(timezone)
    const diffForHumans = (date: string) => spacetime(date).from(spacetime()).rounded

    return {
        parse,
        ymd,
        niceFullYear,
        niceDate,
        toLocalTime,
        diffForHumans,
        timezone: spacetime().timezone(),
    }
}
