import { ref } from '@vue/runtime-core'

const open = ref(false)
const resources = ref<string[]>([])
const activeIndex = ref(0)

export function useSlideShow() {
    const push = (image: string) => {
        resources.value.push(image)
    }
    const startSlideshow = (image?: string) => {
        open.value = true
        activeIndex.value = image ? resources.value.indexOf(image) || 0 : 0
    }
    const startSlideshowWithImages = (images: string[], activeImage: string) => {
        resources.value = images
        activeIndex.value = images.indexOf(activeImage) || 0
        open.value = true
    }
    const move = (amount: number) => {
        let newActive
        const newIndex = activeIndex.value + amount
        if (newIndex > resources.value.length - 1) newActive = 0
        if (newIndex === -1) newActive = resources.value.length - 1
        activeIndex.value = newActive !== undefined ? newActive : newIndex
    }
    const prev = () => move(-1)
    const next = () => move(1)
    const jump = (index: number) => (activeIndex.value = index)

    return {
        open,
        resources,
        activeIndex,
        push,
        startSlideshow,
        startSlideshowWithImages,
        prev,
        next,
        jump,
    }
}
