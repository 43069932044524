import axios from 'axios'
import Pusher, { ChannelAuthorizationCallback } from 'pusher-js/with-encryption'

import mitt from 'mitt'
import { ref } from '@vue/reactivity'

import { ChannelAuthorizerGenerator } from 'pusher-js/types/src/core/auth/deprecated_channel_authorizer'

const emitter = mitt()
const connected = ref<boolean>(false)

const authorizer: ChannelAuthorizerGenerator = (channel) => {
    return {
        authorize: (socketId: string, callback: ChannelAuthorizationCallback) => {
            axios
                .post('/broadcasting/auth', {
                    socket_id: socketId,
                    channel_name: channel.name,
                })
                .then(({ data }) => callback(null, data))
                .catch((error) => callback(error, null))
        },
    }
}

const pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
    wsHost: import.meta.env.VITE_PUSHER_APP_HOST,
    wsPort: import.meta.env.VITE_PUSHER_APP_PORT,
    wssPort: import.meta.env.VITE_PUSHER_APP_PORT,
    forceTLS: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    authorizer,
    authEndpoint: 'broadcasting/auth',
})

pusher.connection.bind('connected', () => {
    connected.value = true
    emitter.emit('connected')
})

export function usePusher() {
    const connect = (channel: string) => pusher.subscribe(channel)
    const connectPrivately = (channel: string) => pusher.subscribe(`private-${channel}`)
    const connectPresence = (channel: string) => pusher.subscribe(`presence-${channel}`)
    const disconnectPrivateChannel = (channel: string) => disconnect(`private-${channel}`)
    const disconnect = (channel: string) => pusher.unsubscribe(channel)

    return {
        pusher,
        emitter,
        connected,
        connect,
        connectPrivately,
        connectPresence,
        disconnect,
        disconnectPrivateChannel,
    }
}
