<template>
    <DialogModal
        :show="show"
        :as="as"
        :max-width="maxWidth"
        @close="close"
        @after-leave="redirect"
        @submit="$emit('submit', $event)"
    >
        <template #title>
            {{ title }}
        </template>

        <slot />

        <template v-if="$slots.actions" #actions>
            <slot name="actions" />
        </template>
    </DialogModal>
</template>

<script setup lang="ts">
    import { useModal } from 'momentum-modal'

    defineProps({
        title: {
            type: String,
            required: true,
        },
        as: {
            type: String,
            default: 'div',
        },
        maxWidth: {
            type: String,
            default: '2xl',
        },
    })
    defineEmits(['submit'])
    const { show, close, redirect } = useModal()
</script>
