<template>
    <div class="flex justify-center">
        <div
            class="from-body-top to-body-bottom max-w-sm rounded border-2 border-gray-50 border-opacity-20 bg-gradient-to-b py-4 shadow-lg md:px-8"
        >
            <h3
                v-if="displayTitle.length > 1"
                class="text-center text-lg font-medium leading-6 text-gray-900"
            >
                {{ displayTitle }} {{ trans('will start in') }}
            </h3>

            <h4 class="text-secondary text-center text-lg font-medium leading-6"></h4>
            <div class="px-4">
                <div class="my-4 flex justify-center">
                    <div class="border-body border-r px-4 text-center">
                        <span class="text-5xl text-primary-400">{{ time.d }}</span>
                        <br />
                        <span class="text-sm uppercase text-gray-500">{{ trans('Days') }}</span>
                    </div>
                    <div class="border-body border-r px-4 text-center">
                        <span class="text-5xl text-primary-400">{{ time.h }}</span>
                        <br />
                        <span class="text-sm uppercase text-gray-500">{{ trans('Hours') }}</span>
                    </div>
                    <div class="border-body border-r px-4 text-center">
                        <span class="text-5xl text-primary-400">{{ time.m }}</span>
                        <br />
                        <span class="text-sm uppercase text-gray-500">{{ trans('Minutes') }}</span>
                    </div>
                    <div class="px-4 text-center">
                        <span class="text-5xl text-primary-400">{{ time.s }}</span>
                        <br />
                        <span class="text-sm uppercase text-gray-500">{{ trans('Seconds') }}</span>
                    </div>
                </div>
            </div>
            <div class="mb-4 mt-5 w-full border-t border-gray-200">
                <h2 class="text-body pt-2.5 text-center text-sm font-normal">
                    {{ trans('Show will begin') }}, {{ opens.format('nice') }}
                </h2>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { ref, onMounted, onUnmounted } from 'vue'
    import { useSpacetime } from '@/scripts/hooks/useSpacetime'
    import spacetime from 'spacetime'

    defineEmits(['countdown:complete'])

    const props = defineProps({
        draw: {
            type: Object,
            required: true,
        },
        displayTitle: {
            type: String,
            default: '',
        },
    })

    const time = ref({
        d: 0,
        h: 0,
        m: 0,
        s: 0,
        show: false,
    })

    const { toLocalTime } = useSpacetime()

    const timer = ref(null)
    const opens = toLocalTime(props.draw?.opens)
    const closes = toLocalTime(props.draw?.closes)

    const closeTime = spacetime().isAfter(opens)
        ? closes.toNativeDate().getTime()
        : opens.toNativeDate().getTime()

    const calcDiff = () => {
        const now = new Date().getTime()

        const distance = closeTime - now

        if (distance < 800 || distance < 0) {
            time.value.d = time.value.h = time.value.m = time.value.s = 0
            clearInterval(timer?.value)
            return
        }

        time.value.d = Math.floor(distance / (1000 * 60 * 60 * 24))
        time.value.h = paddInt(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
        time.value.m = paddInt(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)))
        time.value.s = paddInt(Math.floor((distance % (1000 * 60)) / 1000))
    }

    const paddInt = (int) => (int < 10 ? `0${int}` : int)

    calcDiff()
    onMounted(() => {
        init()
    })

    onUnmounted(() => {
        clearInterval(timer?.value)
    })

    const init = () => {
        timer.value = setInterval(() => {
            calcDiff()
        }, 1000)
    }
</script>
