import { defineAsyncComponent } from '@vue/runtime-core'
import Spinner from '@/views/components/Icons/Spinner.vue'
import CircleExclamation from '@/icons/duotone/circle-exclamation.svg'

const components = import.meta.globEager('../../icons/**/*.svg')

const resolveIconComponent = async (name: string, type: string) => {
    const componentName = name + '.svg'

    for (const path in components) {
        if (path.endsWith(`/${type.trim()}/${componentName}`)) {
            return await components[path]
        }
    }

    return Promise.reject(`Icon component ${name} from ${type} set not found`)
}

// @ts-ignore
export function useAsyncIcon(name: string, type: string): ComponentDefinition {
    return defineAsyncComponent({
        loader: () => resolveIconComponent(name, type),
        loadingComponent: Spinner,
        delay: 500,
        // @ts-ignore
        errorComponent: CircleExclamation,
        timeout: 5000,
    })
}
