import { Plugin } from '@vue/runtime-core'

export const globalComponentsPlugin: Plugin = {
    install(app) {
        const components = import.meta.globEager('../../views/components/**/*.vue')

        Object.entries(components).forEach(([path, definition]) => {
            const componentName = path
                .split('/')
                .pop()
                ?.replace(/\.\w+$/, '')

            if (typeof componentName === 'undefined') {
                throw new Error(`Could not extract component name from path: ${path}`)
            }

            app.component(componentName, definition.default)
        })
    }
}
