<template>
    <div :class="{ 'space-y-1': !horizontal, 'grid grid-cols-3 items-center': horizontal }">
        <Label v-if="label" :for="id">
            {{ label }}
            <span v-if="required" class="text-red-500">*</span>
        </Label>

        <Combobox
            :id="id"
            :model-value="modelValue"
            :autofocus="autofocus"
            :errored="hasError(name)"
            :name="name"
            :display-value="displayValue"
            :multiple="multiple"
            :tagged="tagged"
            :creatable="creatable"
            :class="{ 'w-full': !horizontal, 'col-span-2': horizontal }"
            @update:modelValue="$emit('update:modelValue', $event)"
            @search="$emit('search', $event)"
        >
            <slot />
        </Combobox>

        <Error :message="getError(name)" :class="{ 'col-start-2 col-end-3 mt-1': horizontal }" />

        <Info
            :message="getError(name) ? undefined : info"
            :class="{ 'col-start-2 col-end-3 mt-1': horizontal }"
        />
    </div>
</template>

<script setup lang="ts">
    import { useErrors } from '@/scripts/hooks/useErrors'

    defineProps({
        modelValue: [String, Number, Array],
        id: {
            type: String,
            required: false,
        },
        label: {
            type: String,
            required: false,
        },
        name: {
            type: String,
            required: true,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        displayValue: {
            type: Function,
            default: (value: any) => value,
        },
        horizontal: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        info: {
            type: String,
            required: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        tagged: {
            type: Boolean,
            default: false,
        },
        creatable: {
            type: Boolean,
            default: false,
        },
    })
    defineEmits(['update:modelValue', 'search'])
    const { hasError, getError } = useErrors()
</script>
