import { ref } from '@vue/reactivity'

const open = ref<boolean>(false)
// @ts-ignore
const resolvePromise = ref<(value: T | PromiseLike<T> | null) => void>(null)
const title = ref<string | null>(null)
const information = ref<string | null>(null)
const action = ref<string>('')
export function useConfirm() {
    const confirm = (
        message: string,
        subtext: string | null = null,
        confirmAction = 'Delete',
    ) => {
        open.value = true
        title.value = message
        information.value = subtext
        action.value = confirmAction

        // @ts-ignore
        return new Promise((resolve: (value: T | PromiseLike<T>) => void) => {
            resolvePromise.value = resolve
        })
    }

    return {
        open,
        resolvePromise,
        title,
        information,
        action,
        confirm,
    }
}
