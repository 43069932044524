<template>
    <div
        class="flex flex-col bg-white shadow-sm transition-colors duration-150 ease-in focus:outline-none"
        :class="{
            'border-primary-500': !errored,
            'border-red-500': errored,
            'ring-1 ring-primary-500': focused && !errored,
            'ring-1 ring-red-500': focused && errored,
            'fixed inset-0 z-20': expanded,
            'rounded-md border': !expanded,
        }"
    >
        <div class="px-3 pt-3">
            <EditorButtons
                v-model:expanded="expanded"
                :editor="editor"
                :buttons="requestedExtensions"
                class="border-b pb-3"
                @gallery:show="showGalleryModal = true"
                @links:handle="handleLinksModal(editor)"
            />
        </div>
        <div class="min-h-0 flex-1">
            <EditorContent
                :editor="editor"
                class="flex min-h-24 overflow-y-auto"
                :class="{ 'max-h-48': !expanded, 'h-full': expanded }"
            />
        </div>

        <DialogModal
            :show="showLinksModal"
            as="form"
            @close="closeLinksModal"
            @submit="submitLinksForm(editor)"
        >
            <template #title>Add link</template>

            <div class="space-y-6">
                <InputGroup
                    id="tiptap-text"
                    v-model="linksForm.text"
                    name="tiptap_text"
                    label="Text"
                />

                <div class="space-y-1">
                    <Label for="tiptap-link">Link</Label>

                    <Input
                        id="tiptap-link"
                        v-model="linksForm.link"
                        :errored="!!linksForm.error"
                        name="tiptap_link"
                        class="w-full"
                    />

                    <Error :message="linksForm.error" />
                </div>
            </div>

            <template #actions>
                <Button variant="secondary" @click="closeLinksModal">Cancel</Button>

                <Button type="submit">Attach</Button>
            </template>
        </DialogModal>
    </div>
</template>

<script setup lang="ts">
    import { computed, ref, watch } from 'vue'
    import { EditorContent } from '@tiptap/vue-3'
    import { useTipTap } from '@/scripts/hooks/useTipTap'
    import { useTipTapLinks } from '@/scripts/hooks/useTipTapLinks'
    import { useGallery } from '@/scripts/hooks/useGallery'

    const props = defineProps({
        modelValue: {
            type: String,
            default: '',
        },
        errored: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        headings: {
            type: Boolean,
            default: false,
        },
        lists: {
            type: Boolean,
            default: false,
        },
        links: {
            type: Boolean,
            default: false,
        },
        images: {
            type: Boolean,
            default: false,
        },
    })
    const emit = defineEmits(['update:modelValue'])
    const expanded = ref(false)
    const focused = ref(false)
    const requestedExtensions = computed(() => {
        const extensions = []
        if (props.headings) extensions.push('headings')
        if (props.lists) extensions.push('lists')
        if (props.links) extensions.push('links')
        if (props.images) extensions.push('images')
        return extensions
    })
    const editor = useTipTap(
        {
            content: props.modelValue,
            onUpdate: () => emit('update:modelValue', editor.value?.getHTML()),
            onFocus: () => (focused.value = true),
            onBlur: () => (focused.value = false),
            autofocus: props.autofocus,
        },
        requestedExtensions.value,
    )
    const { show: showGalleryModal } = useGallery()
    const {
        show: showLinksModal,
        form: linksForm,
        handle: handleLinksModal,
        close: closeLinksModal,
        submit: submitLinksForm,
    } = useTipTapLinks()
    watch(
        () => props.modelValue,
        (value) => {
            if (editor.value?.getHTML() === value) return
            editor.value?.commands.setContent(value, false)
        },
    )
</script>
