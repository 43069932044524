<template>
    <div :class="classList">
        <svg v-if="dotted" :class="dotClassList" fill="currentColor" viewBox="0 0 8 8">
            <circle cx="4" cy="4" r="3" />
        </svg>

        <slot />
    </div>
</template>

<script setup lang="ts">
    import { defineProps, computed } from '@vue/runtime-core'

    const props = defineProps({
        variant: {
            type: String,
            default: 'neutral',
        },
        size: {
            type: String,
            default: 'md',
        },
        dotted: {
            type: Boolean,
            default: false,
        },
        shadow: {
            type: Boolean,
            default: false,
        },
    })
    const variants: { [index: string]: string } = {
        neutral: 'bg-gray-100 text-gray-800',
        info: 'bg-blue-100 text-blue-800',
        success: 'bg-green-100 text-green-800',
        warning: 'bg-yellow-100 text-yellow-800',
        danger: 'bg-red-100 text-red-800',
    }
    const sizes: { [index: string]: string } = {
        sm: 'px-2 py-0.5 text-xs',
        md: 'px-3 py-0.5 text-sm',
        lg: 'px-3 py-1 text-base',
    }
    const classList = computed(() => [
        'inline-flex items-center rounded-full font-medium',
        sizes[props.size],
        variants[props.variant],
        props.shadow ? 'shadow' : '',
    ])
    const dotVariants: { [index: string]: string } = {
        neutral: 'text-gray-400',
        info: 'text-blue-400',
        success: 'text-green-400',
        warning: 'text-yellow-400',
        danger: 'text-red-400',
    }
    const dotClassList = computed(() => ['-ml-1 mr-1.5 h-2 w-2', dotVariants[props.variant]])
</script>
