<template>
    <TransitionRoot appear :show="open" as="template">
        <Dialog class="relative z-50" @close="open = false" @keyup.left="prev" @keyup.right="next">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div class="flex min-h-full items-center justify-center p-4 text-center">
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel
                            class="relative flex min-h-[48rem] max-w-3xl items-center justify-center rounded border border-gray-900 bg-gray-900 md:min-w-[42rem]"
                        >
                            <div class="flex items-center justify-center">
                                <div v-for="(resource, i) in resources" :key="i">
                                    <template v-if="i === activeIndex">
                                        <img :src="resource" alt="photo" />
                                    </template>
                                </div>

                                <button
                                    class="absolute right-0 top-0 p-2 text-gray-700 hover:text-white"
                                    @click="open = false"
                                >
                                    <AsyncIcon name="xmark" class="h-6 w-6" />
                                </button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>

                <DialogPanel v-if="resources.length > 1">
                    <button
                        class="absolute left-8 top-1/2 flex h-10 w-10 -translate-y-1/2 transform items-center justify-center rounded-full border-2 border-gray-50 text-white shadow hover:bg-gray-50 hover:text-gray-900 lg:left-4 xl:left-8"
                        @click.prevent="prev"
                    >
                        <AsyncIcon name="chevron-left" class="h-5 w-5" />
                    </button>

                    <button
                        class="absolute right-8 top-1/2 flex h-10 w-10 -translate-y-1/2 transform items-center justify-center rounded-full border-2 border-gray-50 text-white shadow hover:bg-gray-50 hover:text-gray-900 lg:right-4 xl:right-8"
                        @click.prevent="next"
                    >
                        <AsyncIcon name="chevron-right" class="h-5 w-5" />
                    </button>

                    <ul class="absolute bottom-2 left-1/2 -translate-x-1/2 transform space-x-2">
                        <li
                            v-for="i in resources.length"
                            :key="i"
                            :class="{
                                'w-6': i === activeIndex + 1,
                                'w-3 opacity-40': i !== activeIndex + 1,
                            }"
                            class="inline-block h-3 cursor-pointer rounded-[2rem] bg-gray-50 shadow"
                            style="
                                transition: opacity 0.4s ease-in-out,
                                    width 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                            "
                            @click="jump(i - 1)"
                        ></li>
                    </ul>
                </DialogPanel>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
    import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue'
    import { useSlideShow } from '@/scripts/hooks/useSlideShow'

    const { open, resources, activeIndex, prev, next, jump } = useSlideShow()
</script>
