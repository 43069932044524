import { usePage } from '@inertiajs/inertia-vue3'
import { ComputedRef, computed } from '@vue/runtime-core'
import { SharedProps } from '@/scripts/types/page-props'

export function useNavigation() {
    const { props }: { props: ComputedRef<SharedProps> } = usePage()

    const links = computed(() => props.value.links)

    return {
        links,
    }
}
