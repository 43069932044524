<template>
    <dl>
        <div
            v-for="(column, i) in columns"
            :key="i"
            :class="containerClassList(column)"
            class="space-y-1"
        >
            <dt class="text-xs font-semibold uppercase tracking-wide text-gray-500">
                {{ column.label }}
            </dt>

            <template v-if="column.slot && value(data, column) !== fallbackValue">
                <dd class="text-sm">
                    <Component :is="column.slot.component" v-bind="{ data, ...column.slot.props }">
                        <slot :name="`cell(${column.name})`" :data="data">
                            {{ value(data, column) }}
                        </slot>
                    </Component>
                </dd>
            </template>

            <template v-else>
                <dd class="text-sm">
                    <slot :name="`cell(${column.name})`" :data="data">
                        <div v-if="column.copyable" class="flex items-center gap-x-2">
                            {{ value(data, column) }}
                            <CopyToClipboard :text="value(data, column)" />
                        </div>

                        <template v-else>
                            {{ value(data, column) }}
                        </template>
                    </slot>
                </dd>
            </template>
        </div>
    </dl>
</template>

<script setup lang="ts">
    import { TableColumnInterface, TableColumnsInterface } from '@/scripts/types/tables'
    import { data_get } from '@/scripts/utils'

    defineProps({
        columns: {
            type: Object as () => TableColumnsInterface,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
    })

    const fallbackValue = '—'
    const value = (data: Record<string, any>, column: TableColumnInterface) => {
        const value = data_get(data, column.name, fallbackValue)

        if (!column.formatter) {
            return value
        }

        return value === fallbackValue ? value : column.formatter(value, data)
    }
    const containerClassList = (column: TableColumnInterface) => ({
        'sm:col-span-1': (column.colSpan ?? 1) === 1,
        'sm:col-span-2': (column.colSpan ?? 1) === 2,
    })
</script>
