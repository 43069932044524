import { computed } from '@vue/reactivity'
import { usePage } from '@inertiajs/inertia-vue3'
import { SharedProps } from '@/scripts/types/page-props'
import { ComputedRef } from '@vue/runtime-core'
import { AccountModel, UserModel } from '@/scripts/types/models'

export function useAuth() {
    const { props }: { props: ComputedRef<SharedProps> } = usePage()
    const user = computed(() => <UserModel>props.value?.auth?.user.data)
    const account = computed(() => <AccountModel>props.value?.auth?.user?.data?.account)
    const classrooms = computed(() => props.value.auth.user.data.classrooms)
    const loggedIn = computed(() => !!user.value)
    const isAdmin = computed(() => props.value?.auth?.user?.data.role?.name === 'admin')
    const isEditor = computed(() => props.value?.auth?.user?.data.role?.name === 'editor')
    const isExpert = computed(() => props.value?.auth?.user?.data.role?.name === 'expert')
    const isTeacher = computed(() => props.value?.auth?.user?.data.role?.name === 'teacher')
    const isFamily = computed(() => props.value?.auth?.user?.data.role?.name === 'family')
    const isSecretary = computed(() => props.value?.auth?.user?.data.role?.name === 'secretary')
    const isStudent = computed(() => props.value?.auth?.user?.data.role?.name === 'student')
    const isFreeloader = computed(() => props.value?.auth?.user?.data.role?.name === 'freeloader')
    const permissions = computed(() => props.value?.auth.permissions)
    const notificationsCount = computed(() => props.value?.auth?.notifications_count)

    return {
        is_impersonating: computed(() => props.value.auth?.impersonation?.is_impersonating),
        impersonator: computed(() => props.value.auth?.impersonation?.impersonator),
        user,
        account,
        classrooms,
        loggedIn,
        isAdmin,
        isEditor,
        isExpert,
        isTeacher,
        isFamily,
        isSecretary,
        isStudent,
        isFreeloader,
        permissions,
        notificationsCount,
    }
}
