<template>
    <div
        :class="{
            'flex flex-wrap items-center gap-x-2 gap-y-1': !horizontal,
            'grid grid-cols-3 items-center': horizontal,
        }"
    >
        <Checkbox
            :id="id"
            :checked="checked"
            :name="name"
            :class="{ 'order-2 col-span-2': horizontal }"
            @update:modelValue="$emit('update:checked', $event)"
        />

        <Label :for="id" class="leading-none">
            <slot>
                <template v-if="label">
                    {{ label }}
                    <span v-if="required" class="text-red-500">*</span>
                </template>
            </slot>
        </Label>

        <Error
            :message="getError(name)"
            :class="{ 'col-start-2 col-end-4 mt-1': horizontal, 'w-full': !horizontal }"
        />

        <Info
            :message="getError(name) ? undefined : info"
            :class="{ 'order-3 col-start-2 col-end-4 mt-1': horizontal, 'w-full': !horizontal }"
        />
    </div>
</template>

<script setup lang="ts">
    import { useErrors } from '@/scripts/hooks/useErrors'

    defineProps({
        checked: {
            type: Boolean,
            required: true,
        },
        id: {
            type: String,
            required: false,
        },
        label: {
            type: String,
            required: false,
        },
        name: {
            type: String,
            required: true,
        },
        info: {
            type: String,
            required: false,
        },
        horizontal: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
    })
    defineEmits(['update:checked'])
    const { getError } = useErrors()
</script>
