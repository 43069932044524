<template>
    <Listbox
        v-slot="{ open }"
        :model-value="modelValue"
        :multiple="multiple"
        @update:modelValue="emit('update:modelValue', $event)"
    >
        <div class="relative">
            <Float
                placement="bottom"
                :middleware="middleware"
                enter="transition duration-100 ease-out"
                enter-from="transform scale-95 opacity-0"
                enter-to="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leave-from="transform scale-100 opacity-100"
                leave-to="transform scale-95 opacity-0"
                tailwindcss-origin-class
            >
                <ListboxButton
                    class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left text-sm shadow-sm focus:outline-none"
                    :class="{
                        'border-primary-500 focus:border-primary-500 focus:ring-1 focus:ring-primary-500':
                            !errored,
                        'ring-1 ring-primary-500': !errored && open,
                        'border-red-500': errored && !open,
                        'border-red-500 ring-1 ring-red-500': errored && open,
                    }"
                >
                    <div v-if="multiple && tagged" class="relative flex flex-wrap gap-2 pr-7">
                        <template v-if="computedDisplayValue.length">
                            <div
                                v-for="(item, i) in computedDisplayValue"
                                :key="i"
                                class="flex items-center gap-1 rounded bg-primary-50 px-2 py-0.5 text-xs"
                            >
                                <div>{{ item }}</div>

                                <AsyncIcon
                                    name="xmark"
                                    class="h-4 w-4 cursor-pointer text-gray-400 hover:text-black"
                                    @click.prevent="removeTag(i)"
                                />
                            </div>
                        </template>

                        <template v-else>
                            {{ trans(placeholder) }}
                        </template>
                    </div>

                    <template v-if="multiple && !tagged">
                        {{ computedDisplayValue.length }} item selected
                    </template>

                    <span v-if="!multiple && !tagged" class="block truncate">
                        <slot name="display" :value="modelValue">
                            {{ computedDisplayValue || trans(placeholder) }}
                        </slot>
                    </span>

                    <span
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                    >
                        <AsyncIcon
                            name="angle-down"
                            class="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </span>
                </ListboxButton>

                <ListboxOptions
                    class="max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                    <slot />
                </ListboxOptions>
            </Float>
        </div>
    </Listbox>
</template>

<script setup lang="ts">
    import { computed, Ref } from '@vue/reactivity'
    import { watch } from 'vue'
    import { size } from '@floating-ui/dom'
    import { Listbox, ListboxButton, ListboxOptions } from '@headlessui/vue'
    import { Float } from '@headlessui-float/vue'

    const props = defineProps({
        modelValue: {
            type: [String, Number, Array],
        },
        id: {
            type: String,
            required: false,
        },
        name: {
            type: String,
            required: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        errored: {
            type: Boolean,
            default: false,
        },
        displayValue: {
            type: Function,
            default: (value: any) => value,
        },
        placeholder: {
            type: String,
            default: 'Select an option',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        tagged: {
            type: Boolean,
            default: false,
        },
        portal: {
            type: Boolean,
            default: true,
        },
    })
    const emit = defineEmits(['update:modelValue', 'change'])
    const computedDisplayValue = computed(() => props.displayValue(props.modelValue))
    const removeTag = (index: any) => {
        emit(
            'update:modelValue',
            props.modelValue.filter((item, i) => i !== index),
        )
    }
    const middleware = ({
        floatingEl,
        referenceEl,
    }: {
        floatingEl: Ref<HTMLElement>
        referenceEl: Ref<HTMLElement>
    }) => [
        size({
            apply() {
                floatingEl.value.style.width = `${
                    referenceEl.value.getBoundingClientRect().width
                }px`
            },
        }),
    ]

    watch(
        () => props.modelValue,
        (value: any) => emit('change', value),
    )
</script>
