import { EditorOptions, useEditor } from '@tiptap/vue-3'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Strike from '@tiptap/extension-strike'
import Underline from '@tiptap/extension-underline'
import Heading from '@tiptap/extension-heading'
import ListItem from '@tiptap/extension-list-item'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'

export function useTipTap(options?: Partial<EditorOptions> | undefined, extensions: Array<string> = []) {
    const defaultExtensions = [
        Document,
        Paragraph,
        Text,
        Bold,
        Italic,
        Strike,
        Underline,
    ]
    const extensionMap: Record<string, Array<any>> = {
        headings: [
            Heading.configure({
                levels: [1, 2, 3, 4],
            }),
        ],
        lists: [
            ListItem,
            BulletList,
            OrderedList,
        ],
        links: [
            Link,
        ],
        images: [
            Image,
        ],
    }
    const requestedExtensions = () => {
        const result = []
        for (const extension of extensions) {
            if (extensionMap[extension]) {
                result.push(...extensionMap[extension])
            }
        }
        return result
    }

    return useEditor({
        ...options,
        editorProps: {
            attributes: {
                class: 'prose p-4 focus:outline-none flex-1 w-full max-w-full',
            },
        },
        extensions: [
            ...defaultExtensions,
            ...requestedExtensions(),
        ],
    })
}
