import { ComputedRef, computed } from '@vue/runtime-core'
import { usePage } from '@inertiajs/inertia-vue3'
import { SharedProps } from '@/scripts/types/page-props'

export function useDatatable() {
    const { props }: { props: ComputedRef<SharedProps> } = usePage()
    const datatable = computed(() => props.value?.request.datatable)

    return {
        per_page: datatable.value?.per_page || '15',
    }
}
