<template>
    <Card>
        <CardHeader v-if="$slots.header" class="border-b border-gray-200">
            <slot name="header" />
        </CardHeader>

        <div class="divide-y divide-gray-200">
            <slot />
        </div>
    </Card>
</template>
