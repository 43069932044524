import axios from 'axios'
import { computed, ref } from '@vue/reactivity'
import mitt, { Emitter } from 'mitt'
import { EditorAttachmentModel } from '@/scripts/types/models'

type Events = {
    'gallery:attach': EditorAttachmentModel[],
    'gallery:attach-url': string,
    'gallery:close': undefined
}

const emitter: Emitter<Events> = mitt<Events>()
const show = ref(false)
const attachments = ref<EditorAttachmentModel[]>([])
const attachmentsLoaded = ref(false)
const selectedAttachments = ref<EditorAttachmentModel[]>([])

export function useGallery() {
    const attachedCount = computed(() => selectedAttachments.value.length)
    const close = () => {
        selectedAttachments.value = []
        show.value = false
        emitter.emit('gallery:close')
    }
    const loadAttachments = () => axios.get(route('admin.editor-attachments.index'))
        .then(({ data }) => {
            attachments.value = data.attachments
            attachmentsLoaded.value = true
        })
    const attach = (attachment: EditorAttachmentModel) => selectedAttachments.value.push(attachment)
    const detach = (attachment: EditorAttachmentModel) => (selectedAttachments.value = selectedAttachments.value.filter(a => a.id !== attachment.id))
    const isAttached = (attachment: EditorAttachmentModel) => selectedAttachments.value.includes(attachment)
    const toggleAttach = (attachment: EditorAttachmentModel) => isAttached(attachment) ? detach(attachment) : attach(attachment)
    const dispatch = () => emitter.emit('gallery:attach', selectedAttachments.value)
    const dispatchUrl = (url: string) => emitter.emit('gallery:attach-url', url)

    emitter.on('gallery:attach', () => {
        selectedAttachments.value = []
        show.value = false
    })

    return {
        show,
        attachments,
        attachmentsLoaded,
        selectedAttachments,
        attachedCount,
        emitter,
        close,
        loadAttachments,
        attach,
        detach,
        isAttached,
        toggleAttach,
        dispatch,
        dispatchUrl,
    }
}
