<template>
    <div
        class="group aspect-h-7 aspect-w-10 relative cursor-pointer rounded border border-gray-400"
        @click="startSlideshow(image)"
    >
        <img
            :src="image"
            alt="photo"
            class="pointer-events-none object-cover group-hover:opacity-50"
        />

        <div class="absolute inset-0 flex items-center justify-center">
            <AsyncIcon
                name="magnifying-glass"
                class="hidden h-10 w-10 text-gray-700 group-hover:block"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { onMounted } from '@vue/runtime-core'
    import { useSlideShow } from '@/scripts/hooks/useSlideShow'

    const props = defineProps({
        image: {
            type: String,
            required: true,
        },
    })
    const { push, startSlideshow } = useSlideShow()

    onMounted(() => push(props.image))
</script>
