<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative z-10" @close="close">
            <TransitionChild
                as="template"
                enter="ease-in-out duration-500"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-500"
                leave-from="opacity-100"
                leave-to="opacity-0"
                @after-leave="redirect"
            >
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-hidden">
                <div class="absolute inset-0 overflow-hidden">
                    <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <TransitionChild
                            as="template"
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enter-from="translate-x-full"
                            enter-to="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leave-from="translate-x-0"
                            leave-to="translate-x-full"
                        >
                            <DialogPanel class="pointer-events-auto w-screen max-w-md">
                                <Component
                                    :is="as"
                                    class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                                    @submit.prevent="$emit('submit', $event)"
                                >
                                    <div
                                        class="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6"
                                    >
                                        <div class="px-4 sm:px-6">
                                            <div class="flex items-start justify-between">
                                                <DialogTitle
                                                    class="text-lg font-medium text-gray-900"
                                                >
                                                    {{ title }}
                                                </DialogTitle>

                                                <div class="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                        @click="close"
                                                    >
                                                        <span class="sr-only">Close panel</span>

                                                        <AsyncIcon
                                                            name="xmark"
                                                            class="h-6 w-6"
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="relative mt-6 flex-1 px-4 sm:px-6">
                                            <slot />
                                        </div>
                                    </div>

                                    <div
                                        v-if="$slots.actions"
                                        class="flex flex-shrink-0 justify-end gap-x-4 px-4 py-4"
                                    >
                                        <slot name="actions" />
                                    </div>
                                </Component>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
    import {
        Dialog,
        DialogPanel,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
    } from '@headlessui/vue'
    import { useModal } from 'momentum-modal'

    defineProps({
        title: {
            type: String,
            required: true,
        },
        as: {
            type: String,
            default: 'div',
        },
    })
    defineEmits(['submit'])
    const { show, close, redirect } = useModal()
</script>
