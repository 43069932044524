<template>
    <input
        ref="input"
        :value="modelValue"
        :class="classList"
        class="rounded-md border px-3 py-2 shadow-sm transition-colors duration-150 ease-in focus:outline-none sm:text-sm"
        @input="$emit('update:modelValue', $event.target.value)"
        @blur="$emit('blur', $event)"
    />
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue'
    import { Ref, computed } from 'vue'

    const props = defineProps({
        modelValue: [String, Number],
        errored: {
            type: Boolean,
            default: false,
        },
    })
    defineEmits(['update:modelValue', 'blur'])
    const input: Ref = ref(null)
    const focus = () => input.value.focus()
    const classList = computed(() => [
        props.errored
            ? 'border-red-500 focus:ring-red-500 focus:border-red-500'
            : 'border-primary-300 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500',
    ])
    defineExpose({ focus })
    onMounted(() => input.value.hasAttribute('autofocus') && focus())
</script>
