<template>
    <MenuItems
        :class="classList"
        class="rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
    >
        <slot />
    </MenuItems>
</template>

<script setup lang="ts">
    import { MenuItems } from '@headlessui/vue'
    import { computed } from 'vue'

    const props = defineProps({
        width: {
            type: String,
            default: 'md',
            validator: (value: string) => ['sm', 'md', 'lg'].includes(value),
        },
    })
    const widths: { [index: string]: string } = {
        sm: 'w-24',
        md: 'w-48',
        lg: 'w-56',
    }
    const classList = computed(() => [widths[props.width]])
</script>
