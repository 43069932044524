<template>
    <Card>
        <CardHeader>
            <CardTitle>{{ title }}</CardTitle>

            <CardDescription>{{ description }}</CardDescription>
        </CardHeader>

        <CardBody>
            <div class="relative w-full p-6">
                <div
                    v-show="loading"
                    class="absolute flex h-full w-full items-center justify-center bg-white bg-opacity-50"
                    :class="chartClasses"
                >
                    <Spinner class="text-green h-12 w-12" :busy="true" />
                </div>
                <canvas ref="chart" class="relative h-full w-full" :class="chartClasses"></canvas>
            </div>
        </CardBody>
    </Card>
</template>

<script lang="ts" setup>
    import { ref } from '@vue/reactivity'

    import { Chart, registerables, ChartData } from 'chart.js'
    import { onMounted } from 'vue'
    import axios from 'axios'

    Chart.register(...registerables)

    const props = defineProps({
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },

        chartClasses: {
            type: String,
            default: '',
        },

        options: {
            type: Object,
            default: () => ({}),
        },
    })

    const defaults = {
        responsive: true,
        elements: {
            point: {
                pointStyle: 'line',
            },
        },

        plugins: {
            legend: {
                // display: false,
                labels: {
                    color: 'rgb(255, 99, 132)',
                },
            },
        },
    }

    const loading = ref<boolean>(true)
    const chartData = ref<ChartData<'doughnut', number[], string>>({
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: [],
            },
        ],
    })

    const chart = ref(null)
    const ctx = ref<Element>(null)
    const rendered = ref<Chart>(null)

    onMounted(() => {
        ctx.value = chart.value?.getContext('2d')
        load()
    })

    const load = async () => {
        const { data } = await axios.get(props.url)

        chartData.value = data
        if (rendered.value) {
            rendered.value?.destroy()
        }

        rendered.value = new Chart(ctx.value, {
            type: 'bar',
            data: chartData.value,
            options: {
                ...defaults,
                ...props.options,
            },
        })

        setTimeout(() => (loading.value = false), 100)
    }
</script>
