import { ref, reactive, watch } from '@vue/runtime-core'
import { Editor } from '@tiptap/vue-3'
import { isValidUrl } from '@/scripts/utils'

export function useTipTapLinks() {
    const show = ref(false)
    const form = reactive({
        text: '',
        link: '',
        error: '',
    })
    const handle = (editor: Editor) => {
        if (editor.isActive('link')) {
            editor.chain().focus().unsetLink().run()

            return
        }

        if (! editor.view.state.selection.empty) {
            const { view, state } = editor
            const { from, to } = view.state.selection

            form.text = state.doc.textBetween(from, to, '')
        }

        show.value = true
    }
    const close = () => (show.value = false)
    const submit = (editor: Editor) => {
        form.error = ''

        if (! form.link) {
            form.error = 'The name field is required.'

            return
        }

        if (! isValidUrl(form.link)) {
            form.error = 'Link must be a valid URL.'

            return
        }

        if (! editor.view.state.selection.empty) {
            editor.commands.setLink({ href: form.link })

            show.value = false
            return
        }

        editor.chain().focus().insertContent(`<a href="${form.link}">${form.text || form.link}</a> `).run()

        show.value = false
    }

    watch(show, (value) => {
        if (!value) {
            form.text = ''
            form.link = ''
            form.error = ''
        }
    })

    return {
        show,
        form,
        handle,
        close,
        submit,
    }
}
