<template>
    <Card class="px-6 pb-6 pt-4">
        <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-1">
            <div class="space-y-1">
                <label class="text-xs font-semibold uppercase tracking-wide text-gray-500">
                    Created at
                </label>

                <time :datetime="resource.created_at" class="block text-sm">
                    {{ diffForHumans(resource.created_at) }}
                </time>
            </div>

            <div class="space-y-1">
                <label class="text-xs font-semibold uppercase tracking-wide text-gray-500">
                    Last modified at
                </label>

                <time :datetime="resource.updated_at" class="block text-sm">
                    {{ diffForHumans(resource.updated_at) }}
                </time>
            </div>

            <div v-if="resource.deleted_at" class="space-y-1">
                <label class="text-xs font-semibold uppercase tracking-wide text-gray-500">
                    Deleted at
                </label>

                <time :datetime="resource.deleted_at" class="block text-sm">
                    {{ diffForHumans(resource.deleted_at) }}
                </time>
            </div>
        </div>
    </Card>
</template>

<script setup lang="ts">
    import { useSpacetime } from '@/scripts/hooks/useSpacetime'
    import { ModelTimestamps, SoftDeletableModel } from '@/scripts/types/models'

    defineProps({
        resource: {
            type: Object as () => SoftDeletableModel | ModelTimestamps,
            required: true,
        },
    })
    const { diffForHumans } = useSpacetime()
</script>
