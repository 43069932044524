export function data_get(target: any, path: Array<any> | string, fallback: any) {
    const segments = Array.isArray(path) ? path : path.split('.')
    const [segment] = segments

    let find = target

    if (segment !== '*' && segments.length > 0) {
        if (find[segment] === null || typeof find[segment] === 'undefined') {
            find = typeof fallback === 'function' ? fallback() : fallback
        } else {
            find = data_get(find[segment], segments.slice(1), fallback)
        }
    } else if (segment === '*') {
        const partial = segments.slice(path.indexOf('*') + 1, path.length)

        if (typeof find === 'object') {
            find = Object.keys(find).reduce(
                (build, property) => ({
                    ...build,
                    [property]: data_get(find[property], partial, fallback),
                }),
                {},
            )
        } else {
            find = data_get(find, partial, fallback)
        }
    }
    if (typeof find === 'object') {
        if (Object.keys(find).length > 0) {
            const isArrayTransformable = Object.keys(find).every((index) =>
                index.match(/^(0|[1-9]\d*)$/),
            )

            return isArrayTransformable ? Object.values(find) : find
        }
    } else {
        return find
    }
}

export function slugify(text: string) {
    return text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
}

export function human_file_size(size: number) {
    const i = Math.floor(Math.log(size) / Math.log(1024))
    return `${parseFloat((size / Math.pow(1024, i)).toFixed(2))}${['B', 'kB', 'MB', 'GB', 'TB'][i]}`
}

export function isValidUrl(url: string) {
    try {
        return Boolean(new URL(url))
    } catch(e) {
        return false
    }
}
