export function useClipboard() {
    const copy = (target: any): Promise<void> => {
        if (typeof target === 'function') {
            target = target()
        }

        if (typeof target === 'object') {
            target = JSON.stringify(target)
        }

        return navigator.clipboard.writeText(target)
    }

    return { copy }
}
