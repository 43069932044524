<template>
    <Card>
        <CardHeader v-if="$slots.header" class="border-b border-gray-200">
            <slot name="header" />
        </CardHeader>

        <TransitionRoot as="template" :show="!!errorCount">
            <CardHeader>
                <TransitionChild
                    enter="transition duration-200 ease-out"
                    enter-from="-translate-y-5 transform opacity-0"
                    enter-to="translate-y-0 transform opacity-100"
                    leave="transition duration-75 ease-in"
                    leave-from="translate-y-0 transform opacity-100"
                    leave-to="-translate-y-5 transform opacity-0"
                >
                    <Alert
                        :message="`There ${plural('is', errorCount)} ${errorCount} ${plural(
                            'error',
                            errorCount,
                        )}.`"
                        variant="danger"
                        shadow
                    />
                </TransitionChild>
            </CardHeader>
        </TransitionRoot>

        <form class="divide-y divide-gray-200" @submit.prevent="$emit('submit', $event)">
            <slot />

            <div class="flex flex-shrink-0 justify-end gap-x-4 rounded-b bg-gray-50 px-4 py-4">
                <slot name="actions" />
            </div>
        </form>
    </Card>
</template>

<script setup lang="ts">
    import { useErrors } from '@/scripts/hooks/useErrors'
    import { usePluralize } from '@/scripts/hooks/usePluralize'
    import { TransitionRoot, TransitionChild } from '@headlessui/vue'

    defineEmits(['submit'])
    const props = defineProps({
        errorBag: {
            type: String,
            required: false,
        },
    })
    const { errorCount } = useErrors(props.errorBag || null)
    const { plural } = usePluralize()
</script>
