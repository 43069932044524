<template>
    <Alert
        v-show="show"
        :message="notification.message"
        :variant="notification.type"
        class="shadow"
    />
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue'
    import { Notification } from '@/scripts/types/notifications'

    const props = defineProps({
        notification: {
            type: Object as () => Notification,
            required: true,
        },
    })
    const emit = defineEmits(['remove'])
    const show = ref(false)
    const fadeOut = () =>
        setTimeout(() => {
            show.value = false
            remove()
        }, props.notification.timeout || 3000)
    const remove = () => setTimeout(() => emit('remove', props.notification.id), 500)

    onMounted(() => {
        show.value = true
        fadeOut()
    })
</script>
