<template>
    <div
        class="relative rounded-lg bg-white shadow ring-1 ring-black ring-opacity-5 focus-within:ring-primary-500"
    >
        <AsyncIcon
            name="magnifying-glass"
            class="pointer-events-none absolute left-4 top-2.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
        />

        <AsyncIcon
            v-if="query && query.length > 0"
            name="xmark"
            class="absolute right-4 top-2.5 h-5 w-5 cursor-pointer text-gray-400 hover:text-primary-500"
            aria-hidden="true"
            @click="query = ''"
        />

        <input
            v-model="query"
            type="text"
            class="h-10 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
            placeholder="Search..."
        />
    </div>
</template>

<script setup lang="ts">
    import { watch } from 'vue'
    import { useDebouncedRef } from '@/scripts/hooks/useDebouncedRef'

    const props = defineProps({
        initialValue: {
            type: String,
            default: '',
        },
        debounce: {
            type: Number,
            default: 500,
        },
    })

    const emit = defineEmits(['search'])
    const query = useDebouncedRef(props.initialValue, props.debounce)

    watch(
        () => query.value,
        (value) => emit('search', value),
    )
</script>
