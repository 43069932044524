import { ref } from '@vue/runtime-core'
import { NotificationType } from '@/scripts/types/notifications'
import { FlashProp } from '@/scripts/types/page-props'
import NotificationPayloadData = App.Data.NotificationPayloadData
import { upperFirst as _upperFirst } from 'lodash'

declare type Notification = { id: string; show: boolean } & NotificationPayloadData

const notifications = ref<Array<Notification>>([])

export function useNotifications() {
    const pushNotification = (notification: Omit<Notification, 'show'>) =>
        notifications.value.push({
            show: true,
            ...notification,
        })
    const removeNotification = (id: string) =>
        setTimeout(
            () =>
            (notifications.value = notifications.value.filter(
                (notification) => notification.id !== id,
            )),
            200,
        )
    const parseNotifications = (flashData: FlashProp) =>
        Object.keys(flashData)
            .filter((type: NotificationType) => flashData[type])
            .map((type) => {
                /* Localize:
                __('Info')
                __('Success')
                __('Warning')
                __('Error')
                */
                type = type === 'danger' ? 'error' : type

                const notification: Omit<Notification, 'show'> = {
                    id: Date.now().toString(),
                    type,
                    title: trans(_upperFirst(type)),
                    subtitle: trans(flashData[type]),
                }
                return notification
            })

    return {
        notifications,
        pushNotification,
        removeNotification,
        parseNotifications,
    }
}
