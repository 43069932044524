<template>
    <div class="mt-8 flex flex-col">
        <div class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full max-w-full py-2 align-middle md:px-6 lg:px-8">
                <div
                    class="divide-y divide-gray-300 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
                >
                    <slot name="header">
                        <div
                            v-if="perPage"
                            class="flex items-center gap-x-4 bg-gray-50 px-4 py-3 sm:px-6"
                        >
                            <Dropdown v-if="orderable">
                                <DropdownButton
                                    class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                >
                                    <AsyncIcon name="gear" class="h-5 w-5" aria-hidden="true" />
                                </DropdownButton>

                                <DropdownItems width="lg">
                                    <div class="p-4 text-sm">
                                        <CheckboxGroup
                                            v-if="orderable"
                                            id="sort"
                                            v-model:checked="order"
                                            name="sort"
                                            label="Sort"
                                        />
                                    </div>
                                </DropdownItems>
                            </Dropdown>

                            <Listbox v-model="selected" class="ml-auto" @change="limitRows">
                                <template #display="{ value }">Showing {{ value }} rows</template>

                                <ListboxOption value="15">15</ListboxOption>
                                <ListboxOption value="25">25</ListboxOption>
                                <ListboxOption value="50">50</ListboxOption>
                                <ListboxOption value="100">100</ListboxOption>
                            </Listbox>

                            <template v-if="$slots.filters">
                                <Popover placement="bottom-end">
                                    <PopoverButton
                                        class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                                    >
                                        <AsyncIcon
                                            name="filter"
                                            class="h-5 w-5"
                                            aria-hidden="true"
                                        />

                                        <AsyncIcon
                                            name="angle-down"
                                            class="ml-2 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block"
                                            aria-hidden="true"
                                        />
                                    </PopoverButton>

                                    <PopoverPanel width="lg">
                                        <div class="flex flex-col gap-y-3 p-4 text-sm">
                                            <slot name="filters" />
                                        </div>
                                    </PopoverPanel>
                                </Popover>
                            </template>
                        </div>
                    </slot>

                    <div class="relative overflow-x-auto whitespace-nowrap">
                        <table class="min-w-full table-auto divide-y divide-gray-300">
                            <thead class="bg-white">
                                <tr>
                                    <th
                                        v-if="order"
                                        scope="col"
                                        class="relative w-auto py-3.5 pl-3 pr-4 sm:pr-6"
                                    >
                                        <span class="sr-only">Sort</span>
                                    </th>

                                    <th
                                        v-for="(column, i) in columns"
                                        :key="column.name"
                                        :class="{
                                            'pl-4 pr-3 sm:pl-6': i === 0 && !order,
                                            'px-3':
                                                (i > 0 && i < columns.length - 1) ||
                                                actions ||
                                                order,
                                            'pl-3 pr-4 sm:pr-6':
                                                i === columns.length - 1 && !actions,
                                        }"
                                        class="w-auto whitespace-nowrap py-3.5 text-left text-xs font-semibold uppercase tracking-wider text-gray-700"
                                        scope="col"
                                    >
                                        {{ column.label }}
                                    </th>

                                    <th
                                        v-if="actions"
                                        scope="col"
                                        class="relative w-auto py-3.5 pl-3 pr-4 sm:pr-6"
                                    >
                                        <span class="sr-only">Actions</span>
                                    </th>
                                </tr>
                            </thead>

                            <tbody class="divide-y divide-gray-200">
                                <slot>
                                    <template v-if="data && Object.keys(data).length">
                                        <tr v-for="(row, i) in data" :key="i">
                                            <td
                                                v-if="order"
                                                class="w-auto whitespace-nowrap bg-gray-50 pl-4 pr-3 text-sm sm:pl-6"
                                            >
                                                <div class="flex flex-col gap-x-2">
                                                    <button
                                                        class="hover:text-primary-500"
                                                        @click.prevent="$emit('move', 'up', row)"
                                                    >
                                                        <AsyncIcon
                                                            name="chevron-up"
                                                            class="h-5 w-5"
                                                        />
                                                    </button>

                                                    <button
                                                        class="hover:text-primary-500"
                                                        @click.prevent="$emit('move', 'down', row)"
                                                    >
                                                        <AsyncIcon
                                                            name="chevron-down"
                                                            class="h-5 w-5"
                                                        />
                                                    </button>
                                                </div>
                                            </td>

                                            <td
                                                v-for="(column, j) in columns"
                                                :key="column.name"
                                                class="w-auto whitespace-pre-line bg-gray-50 py-3 text-sm"
                                                :class="{
                                                    'pl-4 pr-3 sm:pl-6': j === 0 && !order,
                                                    'px-3':
                                                        (j > 0 && j < columns.length - 1) ||
                                                        actions ||
                                                        order,
                                                    'pl-3 pr-4 sm:pr-6':
                                                        j === columns.length - 1 && !actions,
                                                }"
                                            >
                                                <template v-if="column.slot">
                                                    <Component
                                                        :is="column.slot.component"
                                                        v-bind="{ row, ...column.slot.props }"
                                                    >
                                                        <slot
                                                            :name="`cell(${column.name})`"
                                                            :row="row"
                                                        >
                                                            {{ cellValue(row, column) }}
                                                        </slot>
                                                    </Component>
                                                </template>

                                                <template v-else>
                                                    <template
                                                        v-if="
                                                            cellValue(row, column) !== fallbackValue
                                                        "
                                                    >
                                                        <slot
                                                            :name="`cell(${column.name})`"
                                                            :row="row"
                                                        >
                                                            <div
                                                                v-if="column.copyable"
                                                                class="flex items-center gap-x-2"
                                                            >
                                                                <span class="leading-none">
                                                                    {{ cellValue(row, column) }}
                                                                </span>

                                                                <CopyToClipboard
                                                                    :text="cellValue(row, column)"
                                                                />
                                                            </div>

                                                            <template v-else>
                                                                {{ cellValue(row, column) }}
                                                            </template>
                                                        </slot>
                                                    </template>

                                                    <template v-else>
                                                        {{ fallbackValue }}
                                                    </template>
                                                </template>
                                            </td>

                                            <td
                                                v-if="actions"
                                                class="sticky right-0 top-auto w-auto whitespace-nowrap bg-gray-50 py-3 pl-3 pr-4 text-right shadow sm:pr-6"
                                                :class="{ 'border-t border-gray-200': i > 0 }"
                                            >
                                                <div class="ml-10 inline-flex gap-x-2">
                                                    <slot name="actions" :row="row" />
                                                </div>
                                            </td>
                                        </tr>
                                    </template>

                                    <tr v-else>
                                        <td
                                            :colspan="columns.length + (actions ? 1 : 0)"
                                            class="w-auto whitespace-nowrap bg-gray-50 p-3 text-sm"
                                        >
                                            <div
                                                class="flex flex-col items-center justify-center gap-y-3 p-3"
                                            >
                                                <AsyncIcon
                                                    name="database"
                                                    class="h-10 w-10"
                                                    aria-hidden="true"
                                                />

                                                <span>No matching records found.</span>
                                            </div>
                                        </td>
                                    </tr>
                                </slot>
                            </tbody>
                        </table>
                    </div>

                    <slot name="footer">
                        <TablePagination v-if="paginated && resource?.meta" :meta="resource.meta" />
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue'
    import {
        PaginatedResourceInterface,
        TableColumnInterface,
        TableColumnsInterface,
    } from '@/scripts/types/tables'
    import { data_get } from '@/scripts/utils'
    import { useDatatable } from '@/scripts/hooks/useDatatable'
    import { Inertia } from '@inertiajs/inertia'
    import { PopoverButton } from '@headlessui/vue'

    defineEmits(['move'])
    const props = defineProps({
        columns: {
            type: Object as () => TableColumnsInterface,
            required: true,
        },
        resource: {
            type: Object as () => PaginatedResourceInterface,
            required: true,
        },
        paginated: {
            type: Boolean,
            default: false,
        },
        actions: {
            type: Boolean,
            default: false,
        },
        perPage: {
            type: Boolean,
            default: true,
        },
        orderable: {
            type: Boolean,
            default: false,
        },
    })

    const fallbackValue = '—'
    const cellValue = (row: Record<string, never>, column: TableColumnInterface) => {
        const value = data_get(row, column.name, fallbackValue)

        if (!column.formatter) {
            return value
        }

        return value === fallbackValue ? value : column.formatter(value, row)
    }
    const data = computed(() => (props.paginated ? props.resource.data : props.resource) || [])
    const datatable = useDatatable()
    const selected = ref(datatable.per_page)
    const limitRows = (value: number) =>
        Inertia.get('', { per_page: value, page: 1 }, { preserveState: true })
    const order = ref(false)
</script>
