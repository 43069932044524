<template>
    <span @click="startConfirmingPassword">
        <slot />
    </span>

    <DialogModal :show="confirmingPassword" @close="closeModal">
        <template #title>
            {{ trans(title) }}
        </template>

        <div class="space-y-1">
            <Label for="current-password-confirm">
                {{ trans(content) }}
            </Label>

            <Input
                id="current-password-confirm"
                ref="passwordInput"
                v-model="form.password"
                :errored="!!form.error"
                name="password"
                type="password"
                :placeholder="trans('Password')"
                autocomplete="current-password"
                class="w-full"
            />

            <Error :message="form.error" />
        </div>

        <template #actions>
            <Button variant="secondary" @click="closeModal">
                {{ trans('Cancel') }}
            </Button>

            <Button :disabled="form.processing" @click="confirmPassword">
                {{ trans(button) }}
            </Button>
        </template>
    </DialogModal>
</template>

<script setup lang="ts">
    import axios from 'axios'
    import { ref, reactive, nextTick } from 'vue'
    import { Ref } from 'vue'

    const emit = defineEmits(['confirmed'])
    defineProps({
        title: {
            type: String,
            default: 'Confirm Password',
        },
        content: {
            type: String,
            default: 'For your security, please confirm your password to continue.',
        },
        button: {
            type: String,
            default: 'Confirm',
        },
    })
    const confirmingPassword = ref(false)
    const form = reactive({
        password: '',
        error: '',
        processing: false,
    })
    const passwordInput: Ref = ref(null)
    const startConfirmingPassword = () => {
        axios.get(route('password.confirmation')).then((response) => {
            if (response.data.confirmed) {
                emit('confirmed')
            } else {
                confirmingPassword.value = true
                setTimeout(() => passwordInput.value?.focus(), 250)
            }
        })
    }
    const confirmPassword = () => {
        form.processing = true
        axios
            .post(route('password.confirm'), {
                password: form.password,
            })
            .then(() => {
                form.processing = false
                closeModal()
                nextTick().then(() => emit('confirmed'))
            })
            .catch((error) => {
                form.processing = false
                form.error = error.response.data.errors.password[0]
                passwordInput.value?.focus()
            })
    }
    const closeModal = () => {
        confirmingPassword.value = false
        form.password = ''
        form.error = ''
    }
</script>
