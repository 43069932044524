import { ComputedRef, computed } from '@vue/runtime-core'
import { usePage } from '@inertiajs/inertia-vue3'
import { SharedProps } from '@/scripts/types/page-props'

export function useErrors(errorBag: string|null = null) {
    const { props }: { props: ComputedRef<SharedProps> } = usePage()

    const hasError = (name: string) => errorBag
        ? Object.prototype.hasOwnProperty.call(props.value.errors, errorBag) && Object.prototype.hasOwnProperty.call(props.value.errors[errorBag], name)
        : Object.prototype.hasOwnProperty.call(props.value.errors, name)
    const getError = (name: string) => errorBag
        ? (Object.prototype.hasOwnProperty.call(props.value.errors, errorBag) ? props.value.errors[errorBag][name] : undefined)
        : props.value.errors[name]
    const errorCount = computed(() => {
        return errorBag
            ? (Object.prototype.hasOwnProperty.call(props.value.errors, errorBag) ? Object.keys(props.value.errors[errorBag]).length : 0)
            : Object.keys(props.value.errors).length
    })

    return {
        hasError,
        getError,
        errorCount,
    }
}
