import type { ComponentsConfiguration } from '@flavorly/vanilla-components'

const configuration: ComponentsConfiguration = {
    Alert: {
        fixedClasses: {
            wrapper: 'p-4 pointer-events-auto',
        },
    },
    Dropdown: {
        fixedClasses: {},
        classes: {
            trigger: '',
            wrapper: 'relative',
            container: 'flex items-center content-center h-full',
            chevronIcon: '-mr-1 ml-2 h-3 w-3',
            overlay: 'fixed top-0 right-0 left-0 bottom-0 z-[99] bg-black/50',
            menuWrapper: 'absolute z-[100]',
            menuWrapperLeft: 'absolute origin-top-left left-0',
            menuWrapperRight: 'absolute origin-top-right right-0',
            menuWrapperCenter: 'absolute origin-top-right right-0 left-0 sm:left-auto sm:w-sm',
            menuWrapperCenterFull:
                'absolute origin-top-right right-0 mx-4 left-0 sm:left-auto sm:w-sm',
            dropdown:
                'bg-white rounded-md shadow-lg top-5 focus:ring-0 focus:outline-none z-[99] overflow-hidden',
            roundedFull: 'rounded-lg',
            roundedTop: 'rounded-none rounded-t-lg',
            roundedBottom: 'rounded-none rounded-b-lg',
            roundedLeft: 'rounded-none rounded-l-lg',
            roundedRight: 'rounded-none rounded-r-lg',
            roundedBottomLeft: 'rounded-none rounded-bl-lg',
            roundedBottomRight: 'rounded-none rounded-br-lg',
            roundedTopLeft: 'rounded-none rounded-tl-lg',
            roundedTopRight: 'rounded-none rounded-tr-lg',
            sizeSmall: 'w-48',
            sizeDefault: 'w-56',
            sizeMedium: 'w-64',
            sizeLarge: 'w-72',
            sizeXLarge: 'w-80',
            sizeXXLarge: 'w-96',
            sizeFull: 'w-full',
            menuItemsWrapper: '',
            menuItemsWrapperSpaced: 'space-y-1',
            menuItem: 'group flex items-center w-full  text-sm leading-5 space-x-1 cursor-pointer',
            menuItemHighlighted: 'text-primary-900 bg-primary-100 z-10',
            menuItemNotHighlighted: 'text-gray-700',
            menuItemSpacing: 'px-3 py-2',
            arrow: 'top-[-5px] invisible -z-[1] before:border-b-0 before:border-r-0 before:absolute before:w-2 before:h-2 before:bg-white  before:visible before:rotate-45 before:border before:border-1 before:border-gray-300 before:rounded-sm',
            enterActiveClass: 'transition duration-100 ease-out',
            enterFromClass: 'transform scale-95 opacity-0',
            enterToClass: 'transform scale-100 opacity-100',
            leaveActiveClass: 'transition duration-75 ease-in',
            leaveFromClass: 'transform scale-100 opacity-100',
            leaveToClass: 'transform scale-95 opacity-0',
        },
    },
}

export default configuration
