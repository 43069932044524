<template>
    <input
        v-model="proxyChecked"
        type="radio"
        :value="value"
        class="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
    />
</template>

<script setup lang="ts">
    import { computed } from '@vue/runtime-core'

    const emit = defineEmits(['update:checked'])
    const props = defineProps({
        checked: {
            type: [Array, Boolean, String, Number],
            default: false,
        },
        value: {
            type: [String, Number, Boolean],
            default: null,
        },
    })
    const proxyChecked = computed({
        get() {
            return props.checked
        },
        set(val) {
            emit('update:checked', val)
        },
    })
</script>
