<template>
    <Combobox
        v-slot="{ open }"
        :model-value="modelValue"
        :name="name"
        :multiple="multiple"
        @update:modelValue="emit('update:modelValue', $event)"
    >
        <div class="relative">
            <Float
                placement="bottom"
                :middleware="middleware"
                enter="transition duration-100 ease-out"
                enter-from="transform scale-95 opacity-0"
                enter-to="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leave-from="transform scale-100 opacity-100"
                leave-to="transform scale-95 opacity-0"
                tailwindcss-origin-class
            >
                <div class="relative">
                    <div
                        :class="{
                            'border-red-300': errored,
                            'border-primary-500 focus-within:border-primary-500 focus-within:ring-1 focus-within:ring-primary-500':
                                !errored,
                            'border-red-500 ring-1 ring-red-500': open && errored,
                            'border-primary-500 ring-1 ring-primary-500': open && !errored,
                        }"
                        class="w-full rounded-md border px-3 py-2 text-sm shadow-sm transition-colors duration-150 ease-in focus:outline-none"
                    >
                        <div class="relative flex flex-wrap gap-2 pr-7">
                            <template v-if="multiple && tagged">
                                <div
                                    v-for="(item, i) in computedDisplayValue"
                                    :key="i"
                                    class="flex items-center gap-1 rounded bg-primary-50 px-2 py-0.5 text-xs"
                                >
                                    <div>{{ item }}</div>

                                    <AsyncIcon
                                        name="xmark"
                                        class="h-4 w-4 cursor-pointer text-gray-400 hover:text-black"
                                        @click="removeTag(i)"
                                    />
                                </div>

                                <div
                                    v-if="hiddenValues > 0"
                                    class="flex items-center gap-1 rounded bg-primary-50 px-2 py-0.5 text-xs"
                                >
                                    +{{ hiddenValues }} more...
                                </div>
                            </template>

                            <template v-if="multiple && !tagged">
                                <div v-show="!open">
                                    {{ computedDisplayValue.length }} item selected
                                </div>
                            </template>

                            <ComboboxInput
                                v-if="(multiple && tagged) || open || !multiple"
                                :id="id"
                                :display-value="inputDisplayValue"
                                :autofocus="autofocus"
                                :placeholder="creatable ? '' : 'Search...'"
                                class="flex-1 border-none py-0 pl-0 text-sm focus:ring-0"
                                autocomplete="off"
                                @change="query = $event.target.value"
                            />

                            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center">
                                <AsyncIcon
                                    name="angle-down"
                                    class="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </ComboboxButton>
                        </div>
                    </div>
                </div>

                <ComboboxOptions
                    v-if="!multiple || !creatable || query.length === 0"
                    class="max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                    <slot>
                        <li
                            class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
                        >
                            No results found.
                        </li>
                    </slot>
                </ComboboxOptions>

                <ComboboxOptions
                    v-else
                    class="max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                    <ComboboxOption v-slot="{ active }" :value="query" as="template">
                        <li
                            :class="[
                                'relative cursor-default select-none py-2 pl-3 pr-9',
                                active ? 'bg-primary-600 text-white' : 'text-gray-900',
                            ]"
                        >
                            <span>Add "{{ query }}" to the list.</span>
                        </li>
                    </ComboboxOption>

                    <slot />
                </ComboboxOptions>
            </Float>
        </div>
    </Combobox>
</template>

<script setup lang="ts">
    import { computed, watch } from '@vue/runtime-core'
    import { Ref } from '@vue/reactivity'
    import { size } from '@floating-ui/dom'
    import { useDebouncedRef } from '@/scripts/hooks/useDebouncedRef'
    import {
        Combobox,
        ComboboxButton,
        ComboboxOptions,
        ComboboxOption,
        ComboboxInput,
    } from '@headlessui/vue'
    import { Float } from '@headlessui-float/vue'

    const props = defineProps({
        modelValue: [String, Number, Array],
        id: {
            type: String,
            required: false,
        },
        name: {
            type: String,
            required: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        errored: {
            type: Boolean,
            default: false,
        },
        displayValue: {
            type: Function,
            default: (value: any) => value,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        tagged: {
            type: Boolean,
            default: false,
        },
        creatable: {
            type: Boolean,
            default: false,
        },
    })
    const emit = defineEmits(['update:modelValue', 'change', 'search'])
    const query = useDebouncedRef('', props.creatable ? 0 : 500)
    const middleware = ({
        floatingEl,
        referenceEl,
    }: {
        floatingEl: Ref<HTMLElement>
        referenceEl: Ref<HTMLElement>
    }) => [
        size({
            apply() {
                floatingEl.value.style.width = `${
                    referenceEl.value.getBoundingClientRect().width
                }px`
            },
        }),
    ]
    const removeTag = (index: any) => {
        emit(
            'update:modelValue',
            props.modelValue.filter((item, i) => i !== index),
        )
    }
    const inputDisplayValue = (value: string) => {
        if (props.multiple) return props.tagged ? '' : query.value
        return props.displayValue(value)
    }
    const computedDisplayValue = computed(() => {
        const displayValue = props.displayValue(props.modelValue)

        if (Array.isArray(displayValue) && props.tagged) {
            return displayValue.slice(0, 5)
        }

        return displayValue
    })
    const hiddenValues = computed(() => {
        if (props.multiple && props.tagged) {
            const displayValue = props.displayValue(props.modelValue)

            return displayValue.length - 5 <= 0 ? 0 : displayValue.length - 5
        }

        return 0
    })
    watch(
        () => props.modelValue,
        (value: any) => {
            if (props.creatable) query.value = ''

            emit('change', value)
        },
    )
    watch(
        () => query.value,
        (value: string) => emit('search', value),
    )
</script>
