<template>
    <div :class="{ 'space-y-1': !horizontal, 'grid grid-cols-3 items-center': horizontal }">
        <Label v-if="label" :for="id">
            {{ label }}
            <span v-if="required" class="text-red-500">*</span>
        </Label>

        <Input
            :id="id"
            :disabled="disabled"
            :value="modelValue"
            :name="name"
            :type="type"
            :autocomplete="autocomplete || id"
            :autofocus="autofocus"
            :errored="hasError(name)"
            :placeholder="placeholder"
            :class="{
                'w-full': !horizontal,
                'col-span-2': horizontal,
                'cursor-not-allowed bg-gray-100 opacity-50': disabled,
            }"
            @update:model-value="$emit('update:modelValue', $event)"
            @blur="$emit('blur', $event)"
        />

        <Error :message="getError(name)" :class="{ 'col-start-2 col-end-4 mt-1': horizontal }" />

        <Info
            :message="getError(name) ? undefined : info"
            :class="{ 'col-start-2 col-end-4 mt-1': horizontal }"
        />
    </div>
</template>

<script setup lang="ts">
    import { useErrors } from '@/scripts/hooks/useErrors'

    const props = defineProps({
        modelValue: [String, Number],
        id: {
            type: String,
            required: false,
        },
        label: {
            type: String,
            required: false,
        },
        name: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: 'text',
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        autocomplete: {
            type: String,
            required: false,
        },
        horizontal: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        info: {
            type: String,
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
        },
        errorBag: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    })
    defineEmits(['update:modelValue', 'blur'])
    const { hasError, getError } = useErrors(props.errorBag || null)
</script>
