<template>
    <Modal
        :show="show"
        :max-width="maxWidth"
        :closeable="closeable"
        @close="close"
        @after-leave="$emit('after-leave', $event)"
    >
        <Component
            :is="as"
            class="flex h-full flex-col divide-y divide-gray-200"
            @submit.prevent="$emit('submit', $event)"
        >
            <div class="flex min-h-0 flex-1 flex-col overflow-y-auto">
                <div class="border-b border-gray-200 px-4 py-4 sm:px-6">
                    <div class="flex items-start justify-between">
                        <DialogTitle class="text-lg font-medium text-gray-900">
                            <slot name="title" />
                        </DialogTitle>

                        <div class="ml-3 flex h-7 items-center">
                            <button
                                type="button"
                                class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500"
                                @click="close"
                            >
                                <span class="sr-only">Close panel</span>

                                <AsyncIcon name="xmark" class="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>

                <div class="relative max-h-[42rem] flex-1 overflow-y-auto px-4 py-6 sm:px-6">
                    <slot />
                </div>
            </div>

            <div v-if="$slots.actions" class="flex flex-shrink-0 justify-end gap-x-4 px-4 py-4">
                <slot name="actions" />
            </div>
        </Component>
    </Modal>
</template>

<script setup lang="ts">
    import { DialogTitle } from '@headlessui/vue'

    const emit = defineEmits(['close', 'after-leave', 'submit'])
    defineProps({
        show: {
            type: Boolean,
            default: false,
        },
        maxWidth: {
            type: String,
            default: '2xl',
        },
        closeable: {
            type: Boolean,
            default: true,
        },
        as: {
            type: String,
            default: 'div',
        },
    })
    const close = () => emit('close')
</script>
