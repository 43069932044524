<template>
    <div class="flex flex-col justify-between px-4 py-5 sm:px-6 lg:flex-row lg:items-center">
        <div class="flex flex-1 flex-col">
            <div class="flex min-w-full flex-col">
                <slot />
            </div>
        </div>

        <div class="mt-3 flex gap-x-3 lg:mt-0">
            <slot name="actions" />
        </div>
    </div>
</template>
