<template>
    <div class="flex flex-col gap-4 md:flex-row md:items-center">
        <h1 class="flex-1 text-2xl text-gray-900">
            <slot />
        </h1>

        <div v-if="$slots.actions" class="ml-auto flex items-center gap-4">
            <slot name="actions" />
        </div>
    </div>
</template>
