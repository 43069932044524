<template><img :src="src" alt="Logo" /></template>

<script setup lang="ts">
    import { computed } from 'vue'

    const props = defineProps({
        size: {
            type: String,
            required: false,
            validator: (value: string) => ['sm', 'lg'].includes(value),
        },
    })
    const src = computed(() => `/img/logo-${props.size}.png`)
</script>
